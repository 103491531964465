









import { defineComponent, PropType, ref } from '@vue/composition-api'

import OutputConditionGroup from '@/components/organisms/h/outputCond/OutputConditionGroup.vue'
import {
  tranRefItemOutputCondition,
  outputConditionGroupOutputCondition,
  outputCondUseInfoList,
} from '@/types/entities'
import {
  OUTPUT_COND_DELETE,
  OUTPUT_COND_ITEM,
  OUTPUT_COND_STORE,
} from '@/utils/constant'

export default defineComponent({
  components: {
    OutputConditionGroup,
  },
  props: {
    outputUseList: {
      type: Array as PropType<outputCondUseInfoList>,
      default: () => [],
    },
  },
  setup(_, context) {
    const changeOutputCondition = (
      params: outputConditionGroupOutputCondition
    ) => {
      const cond: tranRefItemOutputCondition = {
        delOutput: params.delOutput || [],
      }
      context.emit('change-output-condition', cond)
    }
    return {
      changeOutputCondition,
    }
  },
})
