





























import { defineComponent, PropType, ref, watch } from '@vue/composition-api'

import HLine from '@/components/atoms/HLine.vue'
import DateGroup from '@/components/organisms/h/item/date/DateGroup.vue'
import ItemHierarchyGroup from '@/components/organisms/h/item/hierarchy/ItemHierarchyGroup.vue'
import ExtractConditionLayout from '@/components/organisms/h/layout/ExtractConditionLayout.vue'
import {
  dateRange,
  dateGroupExtractCondition,
  itemHierarchyGroupExtractCondition,
  itemHierarchyUseList,
  tranRefItemExtractCondition,
} from '@/types/entities'

export default defineComponent({
  components: {
    ExtractConditionLayout,
    HLine,
    DateGroup,
    ItemHierarchyGroup,
  },
  props: {
    defaultDateValue: {
      type: Array as PropType<string[]>,
      default: () => ['', ''],
    },
  },
  setup(props, context) {
    /*********************************
     * 表示する選択肢
     *********************************/
    const referrerItemUseList = ref<itemHierarchyUseList>([['Tanpin']])
    const referenceItemUseList = ref<itemHierarchyUseList>([['Tanpin']])
    /*********************************
     * データ
     *********************************/
    const extractConditionOfDate = ref<dateGroupExtractCondition>({})
    const extractConditionOfReferrerItem =
      ref<itemHierarchyGroupExtractCondition>({})
    const extractConditionOfReferenceItem =
      ref<itemHierarchyGroupExtractCondition>({})

    /*********************************
     * emitデータの作成
     *********************************/
    // いずれかの抽出条件変更時の処理
    const changeExtractCondition = () => {
      const cond: tranRefItemExtractCondition = {
        validData:
          extractConditionOfDate.value.dateRange ||
          (props.defaultDateValue as dateRange),
        referrerItem: extractConditionOfReferrerItem.value.tanpin || [],
        referrerItemCount:
          extractConditionOfReferrerItem.value.tanpinCount || 0,
        referenceItem: extractConditionOfReferenceItem.value.tanpin || [],
        referenceItemCount:
          extractConditionOfReferenceItem.value.tanpinCount || 0,
      }
      context.emit('change-extract-condition', cond)
    }

    /*********************************
     * 以下は各抽出条件変更時の処理
     *********************************/
    const changeExtractConditionOfDate = (
      params: dateGroupExtractCondition
    ) => {
      extractConditionOfDate.value = params
      changeExtractCondition()
    }
    const changeExtractConditionOfReferrerItem = (
      params: itemHierarchyGroupExtractCondition
    ) => {
      extractConditionOfReferrerItem.value = params
      changeExtractCondition()
    }
    const changeExtractConditionOfReferenceItem = (
      params: itemHierarchyGroupExtractCondition
    ) => {
      extractConditionOfReferenceItem.value = params
      changeExtractCondition()
    }
    const requiredItemInput = [
      '有効日：常に入力',
      '参照元商品・参照先商品のいずれかを一つ以上入力',
      '※商品の絞り込みは100件まで',
    ]
    const notHierarchicalItem = true
    // 日付のToを非活性にする
    const disabledTo = true
    return {
      disabledTo,
      notHierarchicalItem,
      requiredItemInput,
      referrerItemUseList,
      referenceItemUseList,
      changeExtractConditionOfDate,
      changeExtractConditionOfReferrerItem,
      changeExtractConditionOfReferenceItem,
    }
  },
})
